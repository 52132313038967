













































































































































































































































































.list-integrations {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  .integration-item {
    border: 2px solid #cbcbcb;
    border-radius: 5px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 30px 15px;
    width: 300px;
    cursor: pointer;
    text-align: center;
    h3 {
      font-size: 16px;
    }
    .integration-icon {
      max-height: 80px;
      height: 80px;
      margin: auto;
      display: block;
    }
    &:hover, &.selected {
      border-color: #4284f4;
    }
  }
}

.list-integration-users {
  .integration-item {
    border: 1px solid #cbcbcb;
    box-shadow: 0px 0px 6px #cbcbcb;
    border-radius: 5px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    padding: 10px 20px;
    .title-wrapper {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      h3 {
        font-size: 18px;
      }
    }
    .integration-icon {
      margin-right: 10px;
      flex: 0 0 70px;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: left;
    }
  }
}
